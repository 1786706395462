import adImage from "../../../assets/Samsung-Galaxy-S22-Ultra-Image.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PhoneIcon from "@mui/icons-material/Phone";
import ChatIcon from "@mui/icons-material/Chat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth } from "../../../context/AuthContext";
import { UsedMobileData } from "../UsedMobiles/interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";

const Ads = () => {
  const { user } = useAuth();
  const userId = user ? user.uid : "";

  const usedMobilesData: UsedMobileData[] = useSelector(
    (state: RootState) => state.usedMobiles.usedMobiles
  );

  const [filteredAds, setFilteredAds] = useState<UsedMobileData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");

  const allAds = usedMobilesData.filter((ad) => ad.userId === userId);
  const activeAds = allAds.filter((ad) => !ad.deActivated && !ad.expired);
  const inactiveAds = allAds.filter((ad) => ad.deActivated);
  const pendingAds = allAds.filter((ad) => ad.approved != null);
  const expiredAds = allAds.filter((ad) => ad.expired);

  useEffect(() => {
    let ads = allAds;

    if (filter === "active") {
      ads = activeAds;
    } else if (filter === "inactive") {
      ads = inactiveAds;
    } else if (filter === "pending") {
      ads = pendingAds;
    } else if (filter === "expired") {
      ads = expiredAds;
    }

    if (searchTerm) {
      ads = ads.filter((ad) =>
        ad.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredAds(ads);
  }, [userId, filter, searchTerm, usedMobilesData]);

  // Handle search button click
  const handleSearch = () => {
    const ads = allAds.filter((ad) =>
      ad.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredAds(ads);
  };

  return (
    <div>
      {/* Ad Filter Tabs */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="ad-filters my-3">
          <button
            className={`btn btn-outline-info ${
              filter === "all" ? "active" : ""
            }`}
            onClick={() => setFilter("all")}
          >
            View All ({allAds.length})
          </button>
          <button
            className={`btn btn-outline-info ${
              filter === "active" ? "active" : ""
            }`}
            onClick={() => setFilter("active")}
          >
            Active Ads ({activeAds.length})
          </button>
          <button
            className={`btn btn-outline-info ${
              filter === "inactive" ? "active" : ""
            }`}
            onClick={() => setFilter("inactive")}
          >
            Inactive Ads ({inactiveAds.length})
          </button>
          <button
            className={`btn btn-outline-info ${
              filter === "pending" ? "active" : ""
            }`}
            onClick={() => setFilter("pending")}
          >
            Pending Ads ({pendingAds.length})
          </button>
          <button
            className={`btn btn-outline-info ${
              filter === "expired" ? "active" : ""
            }`}
            onClick={() => setFilter("expired")}
          >
            Expired Ads ({expiredAds.length})
          </button>
        </div>

        {/* Search bar */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="search"
            placeholder="Search By Ad Title..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
            className="form-control"
            style={{
              margin: "0px 5px",
              padding: "1px 5px",
              width: "180px",
              height: "30px",
              borderRadius: "7px",
            }}
          />
          <button
            className="btn btn-info"
            style={{ padding: "0px 3px", borderRadius: "7px" }}
            onClick={handleSearch} // Trigger search on button click
          >
            <SearchIcon />
          </button>
        </div>
      </div>

      {/* Display message if no ads are found */}
      {filteredAds.length === 0 ? (
        <div className="mt-3">No ads found</div>
      ) : (
        filteredAds.map((ad) => (
          <div
            key={ad.newMobileId}
            className="ad-item p-3 my-2 d-flex align-items-center"
          >
            {/* Ad Image */}
            <div className="ad-image">
              <img
                src={ad.photos[0] || adImage}
                alt="Ad Image"
                className="img-fluid rounded"
              />
            </div>

            {/* Ad Details */}
            <div className="ad-details flex-grow-1 mx-3">
              <h5 className="ad-title">
                {ad.title}{" "}
                <span className="ad-category">- in Mobile Phones</span>
              </h5>
              <p className="ad-price">Rs {ad.price}</p>
              <p className="ad-status">
                Active From{" "}
                {new Date(
                  // @ts-ignore
                  ad.postedDate._seconds * 1000
                ).toLocaleDateString()}{" "}
                To {ad.expired ? "Expired" : "Ongoing"}{" "}
                <span
                  className={`badge ${ad.expired ? "bg-danger" : "bg-success"}`}
                >
                  {ad.expired ? "Expired" : "Active"}
                </span>
              </p>

              {/* Ad Stats */}
              <div
                className="ad-stats"
                style={{ display: "flex", gap: "30px" }}
              >
                <div className="stat-item">
                  <VisibilityIcon style={{ fontSize: "20px" }} /> {ad.views}{" "}
                  Views
                </div>
                <div className="stat-item">
                  <PhoneIcon style={{ fontSize: "20px" }} /> 0 Tel
                </div>
                <div className="stat-item">
                  <ChatIcon style={{ fontSize: "20px" }} /> 0 Chats
                </div>
                <div className="stat-item">
                  <FavoriteIcon style={{ fontSize: "20px" }} /> {ad.likes} Likes
                </div>
              </div>
            </div>

            {/* Ad Actions */}
            <div className="ad-actions">
              <button className="btn btn-outline-info mx-2">Edit</button>
              <button className="btn btn-info">Sell Faster Now</button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Ads;

// import adImage from "../../../assets/Samsung-Galaxy-S22-Ultra-Image.jpg";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import PhoneIcon from "@mui/icons-material/Phone";
// import ChatIcon from "@mui/icons-material/Chat";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import SearchIcon from "@mui/icons-material/Search";
// import { useAuth } from "../../../context/AuthContext";
// import { UsedMobileData } from "../UsedMobiles/interface";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../store/store";
// import { useEffect, useState } from "react";

// const Ads = () => {
//   const { user } = useAuth();
//   const userId = user ? user.uid : "";

//   const usedMobilesData: UsedMobileData[] = useSelector(
//     (state: RootState) => state.usedMobiles.usedMobiles
//   );
//   // const userAds = usedMobilesData.filter((ad) => ad.userId === userId);

//   // State to manage filtered ads
//   const [filteredAds, setFilteredAds] = useState<UsedMobileData[]>([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [filter, setFilter] = useState("all");

//   // Calculate the number of ads in each category
//   const allAds = usedMobilesData.filter((ad) => ad.userId === userId);
//   const activeAds = allAds.filter((ad) => !ad.deActivated && !ad.expired);
//   const inactiveAds = allAds.filter((ad) => ad.deActivated);
//   const pendingAds = allAds.filter((ad) => ad.approved != null);
//   const expiredAds = allAds.filter((ad) => ad.expired);

//   useEffect(() => {
//     let ads = allAds;

//     // Apply filters based on the selected filter type
//     if (filter === "active") {
//       ads = activeAds;
//     } else if (filter === "inactive") {
//       ads = inactiveAds;
//     } else if (filter === "pending") {
//       ads = pendingAds;
//     } else if (filter === "expired") {
//       ads = expiredAds;
//     }

//     // Apply search filter
//     if (searchTerm) {
//       ads = ads.filter((ad) =>
//         ad.title.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     }

//     setFilteredAds(ads);
//   }, [userId, filter, searchTerm, usedMobilesData]);

//   return (
//     <div>
//       {/* Ad Filter Tabs */}
//       <div style={{ display: "flex", justifyContent: "space-between" }}>
//         <div className="ad-filters my-3">
//           <button
//             className={`btn btn-outline-info ${
//               filter === "all" ? "active" : ""
//             }`}
//             onClick={() => setFilter("all")}
//           >
//             View All ({allAds.length})
//           </button>
//           <button
//             className={`btn btn-outline-info ${
//               filter === "active" ? "active" : ""
//             }`}
//             onClick={() => setFilter("active")}
//           >
//             Active Ads ({activeAds.length})
//           </button>
//           <button
//             className={`btn btn-outline-info ${
//               filter === "inactive" ? "active" : ""
//             }`}
//             onClick={() => setFilter("inactive")}
//           >
//             Inactive Ads ({inactiveAds.length})
//           </button>
//           <button
//             className={`btn btn-outline-info ${
//               filter === "pending" ? "active" : ""
//             }`}
//             onClick={() => setFilter("pending")}
//           >
//             Pending Ads ({pendingAds.length})
//           </button>
//           <button
//             className={`btn btn-outline-info ${
//               filter === "expired" ? "active" : ""
//             }`}
//             onClick={() => setFilter("expired")}
//           >
//             Expired Ads ({expiredAds.length})
//           </button>
//         </div>

//         {/* Search bar */}
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <input
//             type="search"
//             placeholder="Search By Ad Title..."
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
//             className="form-control"
//             style={{
//               margin: "0px 5px",
//               padding: "1px 5px",
//               width: "180px",
//               height: "30px",
//               borderRadius: "7px",
//             }}
//           />
//           <button
//             className="btn btn-info"
//             style={{ padding: "0px 3px", borderRadius: "7px" }}
//           >
//             <SearchIcon />
//           </button>
//         </div>
//       </div>

//       {/* Ads List */}
//       {filteredAds.map((ad) => (
//         <div
//           key={ad.newMobileId}
//           className="ad-item p-3 my-2 d-flex align-items-center"
//         >
//           {/* Ad Image */}
//           <div className="ad-image">
//             <img
//               src={ad.photos[0] || adImage}
//               alt="Ad Image"
//               className="img-fluid rounded"
//             />
//           </div>

//           {/* Ad Details */}
//           <div className="ad-details flex-grow-1 mx-3">
//             <h5 className="ad-title">
//               {ad.title} <span className="ad-category">- in Mobile Phones</span>
//             </h5>
//             <p className="ad-price">Rs {ad.price}</p>
//             <p className="ad-status">
//               Active From
//               {new Date(
//                 // @ts-ignore
//                 ad.postedDate._seconds * 1000
//               ).toLocaleDateString()}{" "}
//               To {ad.expired ? "Expired" : "Ongoing"}{" "}
//               <span
//                 className={`badge ${ad.expired ? "bg-danger" : "bg-success"}`}
//               >
//                 {ad.expired ? "Expired" : "Active"}
//               </span>
//             </p>

//             {/* Ad Stats */}
//             <div className="ad-stats" style={{ display: "flex", gap: "30px" }}>
//               <div className="stat-item">
//                 <VisibilityIcon style={{ fontSize: "20px" }} /> {ad.views} Views
//               </div>
//               <div className="stat-item">
//                 <PhoneIcon style={{ fontSize: "20px" }} /> 0 Tel
//               </div>
//               <div className="stat-item">
//                 <ChatIcon style={{ fontSize: "20px" }} /> 0 Chats
//               </div>
//               <div className="stat-item">
//                 <FavoriteIcon style={{ fontSize: "20px" }} /> {ad.likes} Likes
//               </div>
//             </div>
//           </div>

//           {/* Ad Actions */}
//           <div className="ad-actions">
//             <button className="btn btn-outline-info mx-2">Edit</button>
//             <button className="btn btn-info">Sell Faster Now</button>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Ads;
