import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./Home/Home";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import NewMobiles from "./NewMobiles";
// import UsedMobiles from "./UsedMobiles";
import MobileDetails from "./MobileDetails";
import UsedMobileDetails from "./UsedMobiles/UsedMobileDetails";
import NewMobiles1 from "./NewMobile1/NewMobile1";
import Mobiles from "./Mobiles/Mobiles";
import ComparisonMobiles from "./ComparisonMobiles";
import AboutUs from "./AboutUs";
import Videos from "./Videos";
import PageNotFound from "./PageNotFound";
import PrivacyPolicy from "./PrivacyPolicy";
import slugs from "./../../../src/config/slugConfig";
import Blogs from "./Blogs";
import BlogPost from "./Blogs/BlogPost";
import TermsAndConditions from "./TermsAndConditions";
import Screen1 from "./SellYourPhone/Screen1";
import Contact from "./Contact";
import Screen2 from "./SellYourPhone/Screen2";
import SignInPage from "./SignInPage";
import SignUpPage from "./SignUpPage";
import UsedMobiles from "./UsedMobiles/Mobiles";
import UserProfile from "./UserProfile";
import UserAds from "./UserAds";

export default function index() {
  return (
    <>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path={`/${slugs.home}`} element={<Home />} />
          <Route
            path={`/${slugs.comparison}`}
            element={<ComparisonMobiles />}
          />
          <Route path={`/${slugs.about}`} element={<AboutUs />} />
          <Route path={`/${slugs.privacy}`} element={<PrivacyPolicy />} />
          <Route path={`/${slugs.terms}`} element={<TermsAndConditions />} />

          {/* <Route path="used-mobile" element={<UsedMobiles />} /> */}
          <Route path={`/${slugs.used_mobiles}`} element={<UsedMobiles />} />
          <Route
            path="used-mobile-details/:adTitle"
            element={<UsedMobileDetails />}
          />

          <Route path={`${slugs.new_mobiles}`} element={<Mobiles />} />
          <Route path="Mobiles" element={<Mobiles />} />
          <Route
            path="mobile-details/:id/:modelName"
            element={<MobileDetails />}
          />
          <Route path={`/${slugs.videos}`} element={<Videos />} />
          {/* <Route path='new-mobile' element={<NewMobiles />} /> */}
          {/* <Route path='new-mobile/:brandName' element={<NewMobiles />} /> */}
          <Route path="*" element={<PageNotFound />} />

          <Route path={`/${slugs.blogs}`} element={<Blogs />} />
          <Route path={`/${slugs.blogs}/:blogTitle`} element={<BlogPost />} />

          <Route path={`/${slugs.contact}`} element={<Contact />} />
          <Route path={`/${slugs.sellMobile_screen1}`} element={<Screen1 />} />
          <Route path={`/${slugs.sellMobile_screen2}`} element={<Screen2 />} />

          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />

          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/user-ads" element={<UserAds />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}
