import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';

// Async thunk for fetching all used mobile data from the API
export const fetchAllUsedMobiles = createAsyncThunk(
    'usedMobiles/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get('https://us-central1-ibad-mobile.cloudfunctions.net/api/usedMobiles');
            return response.data;
        } catch (error) {
            console.error('Error fetching used mobiles:', error);
            return rejectWithValue(error);
        }
    }
);

const usedMobilesSlice = createSlice({
    name: 'usedMobiles',
    initialState: {
        usedMobiles: [] as any[],
        filteredUsedMobiles: [] as any[],
        error: null as string | null,
        status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    },
    reducers: {
        setUsedMobileData: (state, action) => {
            state.usedMobiles = action.payload;
        },
        setFilteredUsedMobiles: (state, action) => {
            state.filteredUsedMobiles = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllUsedMobiles.pending, (state) => {
                state.error = null;
                state.status = 'loading';
            })
            .addCase(fetchAllUsedMobiles.fulfilled, (state, action) => {
           //     console.log('Fetched used mobiles:', action.payload);
                state.usedMobiles = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchAllUsedMobiles.rejected, (state, action) => {
                state.error = action.error.message ?? 'An unknown error occurred';
                state.status = 'failed';
            });
    }
});

export const { setUsedMobileData, setFilteredUsedMobiles } = usedMobilesSlice.actions;
export default usedMobilesSlice.reducer;