import Breadcrumb from "./Breadcrumb";
import Videos from "./Videos";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const VideoComponent = () => {
  const [videosData, setVideosData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          "https://us-central1-ibad-mobile.cloudfunctions.net/api/videos"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const videos = await response.json();
        setVideosData(videos);
        setIsLoading(false);
      } catch (error) {
     //   console.error("Failed to fetch videos:", error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="container my-5">
      <Helmet>
        <title>Mobile Tech Videos | Ibad Mobile</title>
        <meta
          name="description"
          content="Discover the latest in mobile technology with our Mobile Tech Videos. Explore expert insights and stay updated with Ibad Mobile."
        />
        <meta
          name="keywords"
          content="mobile tech videos ,mobile videos, mobile purchasing, mobile technology, Ibad Mobiles"
        />
        <meta name="google-adsense-account" content="ca-pub-3818311597459709"></meta>
      </Helmet>
      <Breadcrumb />
      <Videos videosData={videosData} isLoading={isLoading} />
    </div>
  );
};

export default VideoComponent;
