import { useState } from "react";
import { StateProps, scrollToTop } from "./ParentStates";
import Profile from "../../../../assets/male_profie_placeholder_2.png";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { useAuth } from "../../../../context/AuthContext";
import { UsedMobileData } from "../../UsedMobiles/interface";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import SuccessPopUp from "./SuccessPopUp";

const State5 = ({
  activeState,
  setActiveState,
  userAdData,
  setUserAdData,
}: StateProps) => {
  const { user } = useAuth();
  const [sellerName, setSellerName] = useState(
    userAdData.sellerName || (user && (user.displayName || user.fullName)) || ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    userAdData.phoneNumber || (user && user.mobileNumber) || ""
  );

  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isPosting, setIsPosting] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const isValidPakistaniMobileNumber = (number: string): boolean => {
    const pakistaniMobileRegex = /^((\+92)|0)?3[0-9]{9}$/;
    return pakistaniMobileRegex.test(number);
  };

  const storage = getStorage();

  // async function uploadImage(
  //   imageData: string,
  //   imageName: string
  // ): Promise<string> {
  //   const imageBlob = base64ToBlob(imageData);
  //   const storageRef = ref(storage, `user-ads/${imageName}`);

  //   await uploadBytes(storageRef, imageBlob);

  //   const downloadURL = await getDownloadURL(storageRef);
  //   return downloadURL;
  // }
  async function uploadImage(imageData: string, imageName: string) {
    const imageBlob = base64ToBlob(imageData);
    const uniqueImageName = `${Date.now()}-${imageName}`; // Generate unique name
    const storageRef = ref(storage, `user-ads/${uniqueImageName}`);

    await uploadBytes(storageRef, imageBlob);

    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  }

  function base64ToBlob(base64: string): Blob {
    const byteString = atob(base64.split(",")[1]);
    const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  const handlePostAd = async () => {
    setIsPosting(true);

    if (!sellerName || !phoneNumber) {
      setError("All fields are required");
      setIsPosting(false);
      return;
    }

    if (!isValidPakistaniMobileNumber(phoneNumber)) {
      setError("Invalid mobile number");
      setIsPosting(false);
      return;
    }

    setError("");
    setUserAdData((prevData) => ({
      ...prevData,
      sellerName: sellerName,
      phoneNumber: phoneNumber,
    }));

    const usedMobileData: UsedMobileData = {
      userId: user?.uid || "",
      approved: null,
      contact: phoneNumber,
      deActivated: false,
      description: userAdData.description,
      email: user?.email || "",
      expired: false,
      featured: false,
      featuredCost: 0,
      featuredDays: 0,
      likes: 0,
      modelName: userAdData.model,
      new: false,
      newMobileId: userAdData.newMobileId,
      nonPta: userAdData.category.toLowerCase() === "non pta",
      notWorking: userAdData.condition.includes("Not Working"),
      notificationSent: false,
      openBox: userAdData.condition.includes("Open Box"),
      photos: [],
      // photos: userAdData.images || [],
      postedDate: new Date(),
      price: Number(userAdData.price),
      pta: userAdData.category.toLowerCase() === "pta",
      refurbished: userAdData.condition.includes("Refurbished"),
      simLocked: userAdData.category.toLowerCase() === "sim locked",
      sold: false,
      title: userAdData.title,
      used: userAdData.condition.includes("Used"),
      userName: sellerName,
      variant: {
        internalStorage: parseInt(userAdData.variant.split("/")[1]) || 0,
        priceInPkr: userAdData.price,
        ram: parseInt(userAdData.variant.split("/")[0]) || 0,
      },
      views: 0,
    };

    // console.log("userAdData");
    // console.log({
    //   ...userAdData,
    //   sellerName: sellerName,
    //   phoneNumber: phoneNumber,
    // });

    const collectionName = `usedMobiles-pakistan-${userAdData.province}-${userAdData.city}`;
    const collectionRef = collection(db, collectionName);

    // try {
    //   const uploadPromises = userAdData.images.map((imageData, index) =>
    //     uploadImage(imageData || "", `${index + 1}.jpg`)
    //   );

    //   const imageUrls = await Promise.all(uploadPromises);
    //   usedMobileData.photos = imageUrls;

    //   // Check if the collection has any documents (i.e., if it "exists")
    //   const querySnapshot = await getDocs(collectionRef);

    //   if (querySnapshot.empty) {
    //     console.log(
    //       "Collection does not exist or is empty. Creating new collection..."
    //     );
    //   } else {
    //     console.log("Collection exists. Adding document...");
    //   }

    //   // Add the document to the collection
    //   const newDocRef = doc(collectionRef);
    //   await setDoc(newDocRef, usedMobileData);

    //   console.log("Document added successfully with ID:", newDocRef.id);

    //   setShowSuccessPopup(true);
    // } catch (error) {
    //   alert("Error posting Ad!");
    //   console.error("Error checking collection or adding document:", error);
    // } finally {
    //   setIsPosting(false);
    // }

    try {
      const uploadPromises = userAdData.images.map((imageData, index) =>
        uploadImage(imageData || "", `${index + 1}.jpg`)
      );

      const imageUrls = await Promise.all(uploadPromises);
      usedMobileData.photos = imageUrls;

      const newDocRef = doc(collectionRef);
      await setDoc(newDocRef, usedMobileData);

      console.log("Document added successfully with ID:", newDocRef.id);
      setShowSuccessPopup(true);
    } catch (error) {
      alert("Error posting Ad!");
      console.error("Error checking collection or adding document:", error);
    } finally {
      setIsPosting(false);
    }
  };

  return (
    <>
      {showSuccessPopup && (
        <SuccessPopUp
          onClose={() => {
            navigate("/");
            // setShowSuccessPopup(false);
          }}
        />
      )}
      <div className="row parent-top-banner-state5 m-2 m-md-0">
        <h5 className="head-title">Review Your Details</h5>

        <div style={{ display: "flex", gap: "20px", marginTop: "10px" }}>
          <div>
            <img
              src={Profile}
              alt=""
              style={{ width: "40px", marginTop: "4px" }}
            />
          </div>
          <div className="w-100">
            <label htmlFor="seller-name">Seller Name</label>
            <input
              id="seller-name"
              type="text"
              placeholder="Enter Name"
              value={sellerName}
              onChange={(e) => setSellerName(e.target.value)}
            />
          </div>
        </div>

        <h5 className="head-title mt-3">Let's verify your account</h5>

        <label htmlFor="phone-number">Mobile Phone Number</label>
        {user && user.mobileNumber ? (
          <div className="input-container">
            <input
              id="phone-number"
              type="text"
              placeholder=""
              value={phoneNumber}
              onChange={(e) => {
                const value = e.target.value;
                const startsWithCountryCode = value.startsWith("+92");
                const startsWithZero = value.startsWith("0");

                const maxLength = startsWithCountryCode
                  ? 13
                  : startsWithZero
                  ? 11
                  : 13;

                if (/^\+?[0-9]*$/.test(value) && value.length <= maxLength) {
                  setPhoneNumber(value);
                }
              }}
              onPaste={(e) => e.preventDefault()} // Disable pasting
            />
          </div>
        ) : (
          <div className="input-container">
            <span className="fixed-text">+92 |</span>

            <input
              id="phone-number"
              type="text"
              placeholder=""
              value={phoneNumber}
              onChange={(e) => {
                const value = e.target.value;
                const startsWithCountryCode = value.startsWith("+92");
                const startsWithZero = value.startsWith("0");

                const maxLength = startsWithCountryCode
                  ? 13
                  : startsWithZero
                  ? 11
                  : 13;

                if (/^\+?[0-9]*$/.test(value) && value.length <= maxLength) {
                  setPhoneNumber(value);
                }
              }}
              onPaste={(e) => e.preventDefault()} // Disable pasting
            />
          </div>
        )}

        {error && <div className="text-danger mt-2">{error}</div>}
      </div>

      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <p className="text-info">
            <LightbulbIcon />
          </p>
          <p>
            Post your ad on our platform and effortlessly edit or update it
            anytime
          </p>
        </div>

        <button
          className="btn btn-info"
          onClick={handlePostAd}
          style={{ marginLeft: "10px", zIndex: "0" }}
          disabled={isPosting}
        >
          {/* Post Ad */}
          Post Ad
        </button>
      </div>
    </>
  );
};

export default State5;
