import React, { useEffect, useRef, useState } from "react";
import Profile_Placeholder from "../../../assets/male_profile_placeholder.png";
import InfoIcon from "../../../assets/info_circle.png";
import { City, ICity } from "country-state-city";
import { useAuth } from "../../../context/AuthContext";
import { db } from "../../../config/firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const Profile = () => {
  const [profileProcessing, setProfileProcessing] = useState(false);
  const [userData, setUserData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    gender: "",
    day: "",
    month: "",
    year: "",
    country: "Pakistan",
    city: "",
    aboutMe: "",
    image: "",
  });

  // useEffect(() => {
  //   console.log(userData);
  // }, [userData]);

  const { user } = useAuth();

  // console.log("user in Profile");
  // console.log(user);

  const [profileImage, setProfileImage] = useState(Profile_Placeholder);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [days, setDays] = useState(Array.from({ length: 31 }, (_, i) => i + 1));
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i); // Last 100 years

  // Function to calculate the number of days in a month
  const getDaysInMonth = (month: string, year: number): number[] => {
    const monthIndex = months.indexOf(month);
    const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  };

  const cities = City.getCitiesOfCountry("PK");

  // Handle input change for all fields
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setUserData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      if (name === "month" || name === "year") {
        const selectedYear = parseInt(
          updatedData.year || currentYear.toString(),
          10
        );
        const daysForSelectedMonth = getDaysInMonth(
          updatedData.month,
          selectedYear
        );
        setDays(daysForSelectedMonth); // Update days based on the selected month/year
      }

      return updatedData;
    });
  };

  // Handle profile image upload
  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result as string);

        setUserData({
          ...userData,
          image: reader.result as string,
        });
      };
      reader.readAsDataURL(file); // Convert image to Base64 for preview
    }
  };

  // // Handle form submission
  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   console.log("User Data: ", userData);
  //   // Perform the necessary API calls or state updates here
  // };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setProfileProcessing(true);
    if (!user) {
    //  console.log("No user is logged in");
      return;
    }

    try {
      let imageUrl = userData.image;

      const userObject = {
        fullName: userData.fullName,
        mobileNumber: userData.phoneNumber,
        email: userData.email,
        gender: userData.gender,
        dob: `${userData.day}/${userData.month}/${userData.year}`,
        country: userData.country,
        city: userData.city,
        aboutMe: userData.aboutMe,
        image: "",
      };

      if (
        userData.image !== user.photoURL &&
        userData.image !== Profile_Placeholder
      ) {
        const storage = getStorage();
        const storageRef = ref(storage, `users/${user.uid}/profile.jpg`);

        // Convert Base64 image to Blob
        const response = await fetch(userData.image);
        const blob = await response.blob();

        // Upload the image to Firebase Storage
        await uploadBytes(storageRef, blob);

        // Get the download URL of the uploaded image
        imageUrl = await getDownloadURL(storageRef);

        userObject.image = imageUrl;
      }

      await setDoc(doc(db, "users", user.uid), userObject);

      alert("Profile updated successfully");
   //   console.log("Profile updated successfully");
      setProfileProcessing(false);
    } catch (error) {
   //   console.error("Error updating profile:", error);
      alert("Error updating profile");
      setProfileProcessing(false);
    }
  };

  useEffect(() => {
    const fetchAdditionalUserData = async () => {
      if (user) {
        try {
          // Get the user's additional data from Firestore
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userDataFromFirestore = docSnap.data();
         //   console.log("userDataFromFirestore");
         //   console.log(userDataFromFirestore);
            setUserData((prevData) => ({
              ...prevData,
              fullName:
                user.displayName ||
                userDataFromFirestore.fullName ||
                prevData.fullName,
              email: user.email || prevData.email,
              phoneNumber:
                user.phoneNumber ||
                userDataFromFirestore.mobileNumber ||
                prevData.phoneNumber,
              image:
                user.photoURL ||
                userDataFromFirestore.image ||
                Profile_Placeholder,
              gender: userDataFromFirestore.gender || prevData.gender,
              day: userDataFromFirestore.dob
                ? userDataFromFirestore.dob.split("/")[0]
                : prevData.day,
              month: userDataFromFirestore.dob
                ? userDataFromFirestore.dob.split("/")[1]
                : prevData.month,
              year: userDataFromFirestore.dob
                ? userDataFromFirestore.dob.split("/")[2]
                : prevData.year,
              country: userDataFromFirestore.country || prevData.country,
              city: userDataFromFirestore.city || prevData.city,
              aboutMe: userDataFromFirestore.aboutMe || prevData.aboutMe,
            }));

            if (user.photoURL) {
              setProfileImage(user.photoURL);
            }
          } else {
            console.log("No additional user data found in Firestore");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchAdditionalUserData();
  }, [user]);

  return (
    <>
      <div className="row">
        {/* Profile Section */}
        <div
          className="col-md-5"
          style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        >
          <div className="profile-photo background-grey-a">
            <h5 style={{ fontWeight: "600", marginBottom: "20px" }}>
              Edit Details
            </h5>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <img
                // src={profileImage}
                src={userData.image || profileImage || Profile_Placeholder}
                alt="Profile"
                className="img-fluid rounded-circle"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                {/* <button className="btn btn-info">Upload Photo</button> */}
                {/* Updated Button to use Ref */}
                <button
                  className="btn btn-info"
                  onClick={() => fileInputRef.current?.click()}
                >
                  Upload Photo
                </button>
                <input
                  type="file"
                  ref={fileInputRef} // Using ref instead of document.getElementById
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <img src={InfoIcon} className="info-icon" alt="" />
                  <p>JPG, JPEG, PNG Min: 400px, Max: 1024px</p>
                </div>
              </div>
            </div>
          </div>
          {/* Contact Information */}
          <div className="contact-info background-grey-a">
            <h5 style={{ fontWeight: "600", marginBottom: "20px" }}>
              Contact Information
            </h5>
            {/* <input
              type="text"
              className="form-control"
              name="phoneNumber"
              placeholder="Phone No"
              value={userData.phoneNumber}
              onChange={handleInputChange} 
            /> */}
            <input
              type="text"
              className="form-control"
              id="phone-number"
              name="phoneNumber"
              placeholder="Phone No"
              value={userData.phoneNumber}
              onChange={(e) => {
                const value = e.target.value;
                const startsWithCountryCode = value.startsWith("+92");
                const startsWithZero = value.startsWith("0");

                const maxLength = startsWithCountryCode
                  ? 13
                  : startsWithZero
                  ? 11
                  : 13;

                // Allow only numbers and enforce the length restriction
                if (/^\+?[0-9]*$/.test(value) && value.length <= maxLength) {
                  handleInputChange(e); // Properly invoke the input handler with the event
                }
              }}
              onPaste={(e) => e.preventDefault()} // Prevent pasting in the phone number input
            />

            <input
              type="email"
              className="form-control mt-2"
              name="email"
              placeholder="Email"
              value={userData.email}
              onChange={handleInputChange}
            />
          </div>
          {/* Optional Information */}
          <div className="optional-info background-grey-a">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <p>Facebook</p>
              <button className="btn">Connect Facebook</button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>Google</p>
              <button className="btn">Connect Google</button>
            </div>
          </div>
        </div>

        {/* Details Section */}
        <div
          className="col-md-7 background-grey-b"
          style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <h5 style={{ fontWeight: "600" }}>Edit Details</h5>
          {/* Full Name */}
          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              id="fullName"
              name="fullName"
              value={userData.fullName}
              onChange={handleInputChange}
            />
          </div>

          {/* Date of Birth */}
          <div className="form-group">
            <label>Date of Birth</label>
            <div className="form-row" style={{ display: "flex", gap: "10px" }}>
              <div className="col">
                <select
                  className="form-control"
                  name="day"
                  value={userData.day}
                  onChange={handleInputChange}
                >
                  <option value="" disabled selected>
                    DD
                  </option>
                  {/* Add day options */}
                  {days.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col">
                <select
                  className="form-control"
                  name="month"
                  value={userData.month}
                  onChange={handleInputChange}
                >
                  <option value="" disabled selected>
                    MM
                  </option>
                  {/* Add month options */}
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col">
                <select
                  className="form-control"
                  name="year"
                  value={userData.year}
                  onChange={handleInputChange}
                >
                  <option value="" disabled selected>
                    YYYY
                  </option>
                  {/* Add year options */}
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Gender */}
          <div className="form-group">
            <label htmlFor="gender">Gender</label>
            <select
              className="form-control"
              id="gender"
              name="gender"
              value={userData.gender}
              onChange={handleInputChange}
            >
              <option value="" disabled selected>
                Select Your Gender
              </option>

              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>

            <textarea
              placeholder="About Me (Optional)"
              className="form-control mt-3"
              id="aboutMe"
              name="aboutMe"
              rows={4}
              style={{ resize: "none" }}
              value={userData.aboutMe}
              onChange={handleInputChange}
            ></textarea>
          </div>

          {/* Country and City */}
          <div className="form-group row">
            <div className="col">
              <label htmlFor="country">Country</label>
              <select
                className="form-control"
                id="country"
                name="country"
                value={userData.country}
                onChange={handleInputChange}
              >
                <option value="Pakistan" selected>
                  Pakistan
                </option>
                {/* Add country options */}
              </select>
            </div>
            <div className="col">
              <label htmlFor="city">City</label>
              <select
                className="form-control"
                id="city"
                name="city"
                value={userData.city}
                onChange={handleInputChange}
              >
                <option value="" disabled selected>
                  Select City
                </option>

                {cities &&
                  cities.map((city, i) => (
                    <option key={city.name + i} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                {/* Add city options */}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <p
          style={{
            fontWeight: "500",
            fontSize: "14px",
            textDecoration: "underline",
            margin: "0px",
          }}
        >
          Want to Delete this account ?
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "8px",
            gap: "10px",
          }}
        >
          <button
            className="btn btn-outline-info"
            style={{ fontWeight: 400 }}
            disabled={profileProcessing}
          >
            DISCARD
          </button>
          <button
            className="btn btn-info"
            style={{ fontWeight: 400 }}
            onClick={handleSubmit}
            disabled={profileProcessing}
          >
            SAVE CHANGES
          </button>
        </div>
      </div>
    </>
  );
};

export default Profile;
