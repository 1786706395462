import "./App.scss";
import React from "react";
import Routes from "./pages/Routes";
import DataLoader from "./DataLoader";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BottomNavBar from "./Component/BottomNavbar";

const useScrollToTop = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50); // Wait for 100 milliseconds

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts or the location changes again before the delay is over
  }, [pathname, search]);
};

const App: React.FC = () => {
  useScrollToTop();
  return (
    <Provider store={store}>
      {/* Load data */}
      <DataLoader />
      <Routes />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <BottomNavBar /> */}
    </Provider>
  );
};

export default App;
