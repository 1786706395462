const slugs = {
    home: 'mobile-marketplace',
    comparison: 'mobile-phone-comparison',
    videos: 'mobile-tech-videos',
    about: "aboutus",
    privacy: "privacy-policy",
    terms: "terms-and-conditions",
    blogs: "mobile-tech-blog",
    contact: "contact-us",
    sellMobile_screen1: "sell-your-phone",
    sellMobile_screen2: "post-ad",
    new_mobiles: "mobiles",
    used_mobiles: "used-mobiles",
    user_profile: "user-profile",
};

export default slugs;