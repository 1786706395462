import React, { useState } from "react";
import State1 from "./State1";
import State2 from "./State2";
import State3 from "./State3";
import State4 from "./State4";
import State5 from "./State5";
import Stepper from "./Stepper";

export interface StateProps {
  activeState: number;
  setActiveState: React.Dispatch<React.SetStateAction<number>>;
  userAdData: UserAdData;
  setUserAdData: React.Dispatch<React.SetStateAction<UserAdData>>;
}

export interface UserAdData {
  title: string;
  description: string;
  brand: string;
  model: string;
  variant: string;
  condition: string[];
  category: string;
  price: string;
  images: (string | null)[];
  province: string;
  city: string;
  town: string;
  sellerName: string;
  phoneNumber: string;
  newMobileId: string;
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const ParentStates = () => {
  const [activeState, setActiveState] = useState(1);
  const [userAdData, setUserAdData] = useState<UserAdData>({
    title: "",
    description: "",
    brand: "",
    variant: "",
    model: "",
    condition: [],
    category: "",
    price: "",
    images: Array(6).fill(null),
    province: "",
    city: "",
    town: "",
    sellerName: "",
    phoneNumber: "",
    newMobileId: "",
  });

  const steps = [State1, State2, State3, State4, State5];

  const CurrentStepComponent = steps[activeState - 1] || null;
  return (
    <div>
      <Stepper activeState={activeState} />
      {CurrentStepComponent && (
        <CurrentStepComponent
          activeState={activeState}
          setActiveState={setActiveState}
          userAdData={userAdData}
          setUserAdData={setUserAdData}
        />
      )}
    </div>
  );
};

export default ParentStates;
