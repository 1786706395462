import { configureStore } from '@reduxjs/toolkit';
import mobilesReducer from './slice/mobilesSlice';
import countryReducer from './slice/countrySlice';
import usedMobilesReducer from './slice/usedMobilesSlice';

export const store = configureStore({
    reducer: {
        mobiles: mobilesReducer,
        country: countryReducer,
        usedMobiles: usedMobilesReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
